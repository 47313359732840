import React, { useState, useEffect } from "react";
import "./BusinessLocator.css";
import { Link } from "react-router-dom";
import { locationData } from "./data";
import br from "../../Assets/BusinessLocator/br.png";
import ck from "../../Assets/BusinessLocator/ck.png";
import dd from "../../Assets/BusinessLocator/dd.png";
import kp from "../../Assets/BusinessLocator/kp.png";
import jb from "../../Assets/BusinessLocator/jb.png";
import zt from "../../Assets/BusinessLocator/zt.png";
import marker from "../../Assets/BusinessLocator/marker.png";

const BusinessLocator = () => {
	const [selectedShop, setSelectedShop] = useState(0);
	const [selectedTab, setSelectedTab] = useState("");
	const [data, setData] = useState(locationData);

	useEffect(() => {
		let clonedData = data;
		if (selectedTab) {
			clonedData = locationData.filter((e) => e.brand == selectedTab);
		}
		setData(clonedData);
		setSelectedShop(0);
	}, [selectedTab]);

	return (
		<div className='business-container main'>
			<div className='hero'>
				<h2>Business Locator</h2>
			</div>
			<div className='business-info'>
				<div className='info-text'>
					<h2>Business Locator</h2>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
						obcaecati eius quasi, provident quas exercitationem eaque eveniet
					</p>
				</div>
				<div className='img-div info-img'></div>
				<div class='info-graphic-tomato'></div>
			</div>
			<div className='locator-content'>
				<div className='locator-filter'>
					<div className='tabs'>
						<div className='tab' onClick={() => setSelectedTab("br")}>
							<img src={br} />
							<div className={`marker ${selectedTab == "br" ? "visible" : ""}`}>
								<img src={marker} />
							</div>
						</div>
						<div className='tab' onClick={() => setSelectedTab("ck")}>
							<img src={ck} />
							<div className={`marker ${selectedTab == "ck" ? "visible" : ""}`}>
								<img src={marker} />
							</div>
						</div>
						<div className='tab' onClick={() => setSelectedTab("dd")}>
							<img src={dd} />
							<div className={`marker ${selectedTab == "dd" ? "visible" : ""}`}>
								<img src={marker} />
							</div>
						</div>
						<div className='tab' onClick={() => setSelectedTab("kp")}>
							<img src={kp} />
							<div className={`marker ${selectedTab == "kp" ? "visible" : ""}`}>
								<img src={marker} />
							</div>
						</div>
						<div className='tab' onClick={() => setSelectedTab("jb")}>
							<img src={jb} />
							<div className={`marker ${selectedTab == "jb" ? "visible" : ""}`}>
								<img src={marker} />
							</div>
						</div>
						<div className='tab' onClick={() => setSelectedTab("zt")}>
							<img src={zt} />
							<div className={`marker ${selectedTab == "zt" ? "visible" : ""}`}>
								<img src={marker} />
							</div>
						</div>
					</div>
					<div className=''>
						<div className='filter-indicator'></div>
					</div>
				</div>
				<div className='maps-container'>
					<div className='shops'>
						{data.map((each, index) => (
							<div
								className={
									+selectedShop === index ? "shop-info active" : "shop-info"
								}
								onClick={() => setSelectedShop(index)}
							>
								<div className='icon-address-container'>
									<div className='icon-img'>
										{" "}
										<img src={each.icon}></img>
									</div>
									<div className='info-right'>
										<div className='brand-name'>
											<p>{`${each.brandFullName} #${each.storeId}`}</p>
										</div>
										<div className='address'>
											<p>
												<span>Address:</span> {each.address}
											</p>
											<p>
												<span>Phone:</span>{" "}
												{each.phone ? each.phone : "(123)-456-789"}
											</p>
										</div>
									</div>
								</div>
								<Link>View</Link>
							</div>
						))}
					</div>
					<div className='map'>
						<iframe
							src={data[selectedShop].iframelink}
							width='100%'
							height='100%'
							allowfullscreen=''
							loading='lazy'
							referrerpolicy='no-referrer-when-downgrade'
						></iframe>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BusinessLocator;
