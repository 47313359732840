import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Header from "./Components/Header/Header";
import BusinessLocator from "./Components/BusinessLocator/BusinessLocator";
import Contact from "./Components/ContactUs/Contact";
import AboutUs from "./Components/AboutUs/AboutUs";
import Gallery from "./Components/Gallery/Gallery";
import Footer from "./Components/Footer/Footer";
function App() {
	return (
		<Router>
			<Header />

			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route exact path='/business' element={<BusinessLocator />} />
				<Route exact path='/contact' element={<Contact />} />
				<Route exact path='/aboutus' element={<AboutUs />} />
				<Route exact path='/gallery' element={<Gallery />} />
				{/* <Route path='*' element={<NotFound />} /> */}
			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
