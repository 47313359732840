import React from "react";
import "./Gallery.css";
import Img1 from "../../Assets/Gallery/1.png";
import Img2 from "../../Assets/Gallery/2.png";
import Img3 from "../../Assets/Gallery/3.png";
import Img4 from "../../Assets/Gallery/4.png";
import Img5 from "../../Assets/Gallery/5.png";
import Img6 from "../../Assets/Gallery/6.png";
const Gallery = () => {
	return (
		<div className='gallery-content main'>
			<div className='hero center-hero'>
				<h2>Photo Gallery</h2>
				<p className='scroll'>Scroll-----------</p>
			</div>
			<div className='gallery-main'>
				<div className='tabs'>
					<div className='tab-item'>
						<p>All</p>
					</div>
					<div className='tab-item'>
						<p>Cat 1</p>
					</div>
					<div className='tab-item'>
						<p>Cat 2</p>
					</div>
					<div className='tab-item'>
						<p>Cat 3</p>
					</div>
					<div className='tab-item'>
						<p>Cat 4</p>
					</div>
					<div className='tab-item'>
						<p>Cat 5</p>
					</div>
					<div className='tab-item'>
						<p>Cat 6</p>
					</div>
				</div>
				<div className='gallery-photos'>
					<div className='row'>
						<div>
							<img src={Img1} />
						</div>
						<div>
							<img src={Img2} />
						</div>
					</div>
					<div className='row'>
						<div>
							<img src={Img3} />
						</div>
						<div>
							<img src={Img4} />
						</div>
					</div>
					<div className='row'>
						<div>
							<img src={Img5} />
						</div>
						<div>
							<img src={Img6} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Gallery;
