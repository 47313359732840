import React, { useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import LogoHori from "../../Assets/Header/LogoHori.png";
const Header = () => {
	const navBarRef = useRef();
	const menuIconRef = useRef();
	const openNavOverlay = () => {
		menuIconRef.current.classList.toggle("move");
		navBarRef.current.classList.toggle("open-menu");
	};
	const location = useLocation();
	const collapseNav = () => {
		menuIconRef.current.classList.toggle("move");
		navBarRef.current.classList.toggle("open-menu");
	};
	const current = location.pathname.split("/")[1];

	return (
		<>
			<div className='navbar'>
				<div className='nav-left'>
					<div className='nav-logo'>
						<Link to='/'>
							<img src={LogoHori} />
						</Link>
					</div>
					<div className='menu-icon' onClick={openNavOverlay} ref={menuIconRef}>
						<div className='line1'></div>
						<div className='line2'></div>
						<div className='line3'></div>
					</div>
				</div>

				<div className='nav-right'>
					<div className='phone'>+1 234 576 875</div>
					<div className='nav-btn'>
						<Link to='/business' className=''>
							{" "}
							Business Locator
						</Link>
					</div>
				</div>
			</div>
			<div className='nav-overlay' ref={navBarRef}>
				<div className='nav-links'>
					<ul>
						<li
							className={current.length == 0 ? "nav-active" : ""}
							onClick={collapseNav}
						>
							<Link to='/'>HOME</Link>
						</li>
						<li
							className={current === "aboutus" ? "nav-active" : ""}
							onClick={collapseNav}
						>
							<Link to='/aboutus'>ABOUT US</Link>
						</li>
						<li
							className={current === "business" ? "nav-active" : ""}
							onClick={collapseNav}
						>
							<Link to='/business'>BUSINESS LOCATOR</Link>
						</li>
						<li
							className={current === "gallery" ? "nav-active" : ""}
							onClick={collapseNav}
						>
							<Link to='/gallery'>PHOTO GALLERY</Link>
						</li>
						<li
							className={current === "contact" ? "nav-active" : ""}
							onClick={collapseNav}
						>
							<Link to='/contact'>CONTACT US</Link>
						</li>
					</ul>
				</div>
				<div className='nav-contact'>
					<div>
						<h4>Contact</h4>
					</div>
					<p>{"+91 1234 567 890 \n info@zesttw.com"}</p>
					<p>{"124A Carter Road, \n Park Avanse, Atlanta, 83240"}</p>
					<div className='socials'>
						<a href='#'>
							<i class='bx bxl-instagram bx-sm'></i>
						</a>

						<a href='#'>
							<i class='bx bxl-twitter bx-sm'></i>
						</a>
						<a href='#'>
							<i class='bx bxl-facebook-circle bx-sm'></i>
						</a>
						<a href='#'>
							<i class='bx bxl-youtube bx-sm'></i>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
