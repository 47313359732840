import React, { useEffect } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import LandingImg from "../../Assets/Home/landingHome.png";

const Home = () => {
	return (
		<>
			<div className='home-container main'>
				<div className='landing-container'>
					<div className='landing-content'>
						<div className='landing-heading'>
							<h1>A message from zest is displayed here</h1>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque
								congue arcu
							</p>
						</div>
						<div className='landing-image-section'>
							<img src={LandingImg} className='  img-div' />
							<div className='donut-flex'>
								<div className='donut-1 img-div'></div>
								<div className='donut-2 img-div'></div>
								<div className='donut-3 img-div'></div>
							</div>
						</div>
					</div>
					<p className='scroll'>Scroll-----------</p>
				</div>
				<div className='about-container'>
					<div className='about-row'>
						<div className='about-col'>
							<h2>About Us</h2>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque
								congue arcu
							</p>
							<div className='about-img-1 img-div'></div>
						</div>
						<div className='about-col'>
							<div className='about-img-2 img-div'></div>
							<div>
								<h2>Heading 2 for About us</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque
									congue arcu
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='locator-container'>
					<div className='locator-heading row'>
						<div className='locator-title'>
							<h2>Business Locator</h2>
							<p>
								This is a section of your menu. Give your section a brief
								description
							</p>
						</div>
						<div className='tree-leaf img-div'></div>
					</div>
					<div className='locator-cards-container'>
						<div className='row'>
							<div className='locator-col'>
								<div className='step'>
									<h3>Step 1</h3>
								</div>
								<div className='card-img img-div'></div>
								<div className='step-info locator-col'>
									<h3>Enter your search</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Neque congue arcu
									</p>
								</div>
							</div>
							<div className='locator-col'>
								<div className='step'>
									<h3>Step 2</h3>
								</div>
								<div className='card-img img-div'></div>
								<div className='step-info locator-col'>
									{" "}
									<h3>Find relevant stores</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Neque congue arcu
									</p>
								</div>
							</div>
							<div className='locator-col'>
								<div className='step'>
									<h3>Step 3</h3>
								</div>
								<div className='card-img img-div'></div>
								<div className='step-info locator-col'>
									{" "}
									<h3>Locate and Visit</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Neque congue arcu
									</p>
								</div>
							</div>
						</div>
					</div>

					<Link className='info-btn' to={`/business`}>
						Know More
					</Link>
				</div>
				<div className='gallery-container'>
					<div className='gallery-heading'>
						<h2>Photo Gallery</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
					</div>
					<div className='row'>
						<div className='img-div gal-1 col'></div>
						<div className='img-div gal-2 col'></div>
						<div className='img-div gal-3 col'></div>
						<div className='img-div gal-2 col'></div>
					</div>
					<button className='info-btn'>Know More</button>
				</div>
				<div className='team-container row'>
					<div className='team-img-container'>
						<div className='row'>
							<div className='leaf-1 img-div'></div>
							<div className='team-1 img-div'></div>
						</div>
						<div className='team-2 img-div'></div>
					</div>
					<div className='team-info'>
						<h2>Meet our team</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus
							lorem id penatibus imperdiet. Turpis egestas ultricies purus
							auctor tincidunt lacus nunc.{" "}
						</p>

						<Link className='info-btn team-btn' to={`/business`}>
							Know More
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
