import React from "react";
import "./Footer.css";
import Logo from "../../Assets/Footer/Logo.png";
const Footer = () => {
	return (
		<div className='footer main'>
			<div className='row footer-info'>
				<div>
					<div className='footer-logo'>
						<img src={Logo} />
					</div>
					<div className='footer-contact'>
						<h5>Contact</h5>
						<p>{"+91 1234 567 890 \n info@zesttw.com"}</p>
						<p>{"124A Carter Road, \n Park Avanse, Atlanta, 83240"}</p>
					</div>
				</div>
				<div className='footer-email'>
					<form>
						<p>Never Miss a Donut!</p>
						<input
							className='contact-email'
							type='email'
							placeholder='Email Address'
						/>
						<input type='submit' className='btn-contact' value='Contact Us' />
					</form>
				</div>
			</div>
			<div className='copyright'>
				<div className='cr-info'>
					© 2022 Zest Team Works. All rights Reserved
				</div>
				<div className='socials'>
					<i class='bx bxl-instagram bx-sm'></i>
					<i class='bx bxl-twitter bx-sm'></i>
					<i class='bx bxl-facebook-circle bx-sm'></i>
					<i class='bx bxl-youtube bx-sm'></i>
				</div>
			</div>
		</div>
	);
};

export default Footer;
