import br from "../../Assets/BusinessLocator/br.png";
import ck from "../../Assets/BusinessLocator/ck.png";
import dd from "../../Assets/BusinessLocator/dd.png";
import kp from "../../Assets/BusinessLocator/kp.png";
import jb from "../../Assets/BusinessLocator/jb.png";
import zt from "../../Assets/BusinessLocator/zt.png";

export const locationData = [
	{
		address: "682 boulevard NE, atlanta, GA 30308",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "350432",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.509885039941!2d-84.37362174873326!3d33.77332883972837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50413948d9bdb%3A0x3b0d11cc0c18c06e!2s682%20Boulevard%20NE%2C%20Atlanta%2C%20GA%2030308%2C%20USA!5e0!3m2!1sen!2sin!4v1675442394506!5m2!1sen!2sin",
	},
	{
		address: "1841 piedmont avenue NE, atlanta, GA 30324",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "350437",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.2616789696444!2d-84.36903044873276!3d33.805559538040875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f505cdb02949fb%3A0x48419c60ffb851b4!2s1841%20Piedmont%20Ave%20NE%2C%20Atlanta%2C%20GA%2030324%2C%20USA!5e0!3m2!1sen!2sin!4v1675447447847!5m2!1sen!2sin",
	},
	{
		address: "551 W pike street, lawrenceville, GA 30046",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "353927",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.1335421914396!2d-84.0089756487301!3d33.963406929757795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5be7d512b27f3%3A0x1a0cd15daf1e8aa5!2s551%20W%20Pike%20St%2C%20Lawrenceville%2C%20GA%2030046%2C%20USA!5e0!3m2!1sen!2sin!4v1675447538649!5m2!1sen!2sin",
	},
	{
		address: "2512 blackmon drive,decatur, GA 30033",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "355439",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.6533359353143!2d-84.2884585487329!3d33.795449238570306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f507742bf9813d%3A0xf22fa5e7ff573b23!2s2512%20Blackmon%20Dr%2C%20Decatur%2C%20GA%2030033%2C%20USA!5e0!3m2!1sen!2sin!4v1675447550289!5m2!1sen!2sin",
	},
	{
		address: "4487 roswell road NE, atlanta, GA 30342",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "362923",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.4712406329963!2d-84.38160504873152!3d33.87751543426908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50f16dee97953%3A0x627dd664be87d4d6!2s4487%20Roswell%20Rd%20NE%2C%20Atlanta%2C%20GA%2030342%2C%20USA!5e0!3m2!1sen!2sin!4v1675447597102!5m2!1sen!2sin",
	},
	{
		address: "1942 Atkinson Rd NW Ste 900, Lawrenceville, GA 30043",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "351323",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.5806553015514!2d-84.08221084872991!3d33.97761632901053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5bd180035943f%3A0xa79ee59c686ce964!2s1942%20Atkinson%20Rd%20NW%20%23900%2C%20Lawrenceville%2C%20GA%2030043%2C%20USA!5e0!3m2!1sen!2sin!4v1675447587685!5m2!1sen!2sin",
	},
	{
		address: "4092 Lawrenceville Hwy, Tucker, GA 30084",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "352613",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.5062094144537!2d-84.21733384873197!3d33.850842835667954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a63ad3b42807%3A0x94d0117c5889e27c!2s4092%20Lawrenceville%20Hwy%2C%20Tucker%2C%20GA%2030084%2C%20USA!5e0!3m2!1sen!2sin!4v1675447682837!5m2!1sen!2sin",
	},
	{
		address: "5870 Cumming Hwy NE, Sugar Hill, GA 30518",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "354030",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.136263145165!2d-84.0788475487276!3d34.11726112165208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f590c13beed375%3A0xbacf2ff504f20df6!2s5870%20Cumming%20Hwy%20NE%2C%20Buford%2C%20GA%2030518%2C%20USA!5e0!3m2!1sen!2sin!4v1675447688977!5m2!1sen!2sin",
	},
	{
		address: "1800 Eatonton Rd, Madison, GA 30650",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "357150",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.6245882917424!2d-83.4800309487366!3d33.563128950698086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f686ec2b769ced%3A0x8414be454e9072c5!2s1800%20Eatonton%20Rd%2C%20Madison%2C%20GA%2030650%2C%20USA!5e0!3m2!1sen!2sin!4v1675447744389!5m2!1sen!2sin",
	},
	{
		address: "1270 Spring St NW, Atlanta, GA 30309",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "358682",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.8787427995067!2d-84.39211844873297!3d33.789629338875166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045a3417c7a1%3A0x27c8d16a32f8e250!2s1270%20Spring%20St%20NW%2C%20Atlanta%2C%20GA%2030309%2C%20USA!5e0!3m2!1sen!2sin!4v1675447736373!5m2!1sen!2sin",
	},
	{
		address: "881 Peachtree St NE, Atlanta, GA 30309",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "359606",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.3016402900657!2d-84.38625494873315!3d33.778707939446676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5046844588ac9%3A0xab168a41e1ce6fc7!2s881%20Peachtree%20St%20NE%2C%20Atlanta%2C%20GA%2030309%2C%20USA!5e0!3m2!1sen!2sin!4v1675447763142!5m2!1sen!2sin",
	},
	{
		address: "2550 Lawrenceville Hwy, Decatur, GA 30033",
		icon: dd,
		brand: "dd",
		brandFullName: "Dunkin Donuts",
		storeId: "359791",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.6541223717063!2d-84.26674744873243!3d33.821237837219684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a818cba5230f%3A0xf61feefe9456051a!2s2550%20Lawrenceville%20Hwy%2C%20Decatur%2C%20GA%2030033%2C%20USA!5e0!3m2!1sen!2sin!4v1675447786869!5m2!1sen!2sin",
	},
	{
		address: "1685 Church St 2nd floor, #206, Decatur, GA 30033",
		icon: kp,
		brand: "kp",
		brandFullName: "Kailash Parbat",
		storeId: "Now open",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.6006189699356!2d-84.28384684873281!3d33.796810238499226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50777eb3ae70b%3A0x24b710ac330aabea!2s1685%20Church%20St%202nd%20206%2C%20Decatur%2C%20GA%2030033%2C%20USA!5e0!3m2!1sen!2sin!4v1675448014685!5m2!1sen!2sin",
	},
	{
		address: "670 N Main St Suite A110, Alpharetta, GA 30009",
		icon: kp,
		brand: "kp",
		brandFullName: "Kailash Parbat",
		storeId: "Now open",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.074408749257!2d-84.29006234872799!3d34.0932342229201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f575e97ceaaaab%3A0xad76050c3c0d694f!2s670%20N%20Main%20St%20Suite%20A110%2C%20Alpharetta%2C%20GA%2030009%2C%20USA!5e0!3m2!1sen!2sin!4v1675448026457!5m2!1sen!2sin",
	},
	{
		address: "3230 Caliber St, Suwanee, GA 30024",
		icon: kp,
		brand: "kp",
		brandFullName: "Kailash Parbat",
		storeId: "N.A",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.4372328143922!2d-84.16634794872814!3d34.083937923410446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5996aef5f2a57%3A0x88f86c3eda0d6edf!2s3230%20Caliber%20St%2C%20Suwanee%2C%20GA%2030024%2C%20USA!5e0!3m2!1sen!2sin!4v1675448053838!5m2!1sen!2sin",
	},
	{
		address: "3890 Satellite blvd Duluth Ga 30096",
		icon: kp,
		brand: "kp",
		brandFullName: "Kailash Parbat",
		storeId: "N.A",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.537489292029!2d-84.14467704873032!3d33.95302203030372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a309f4355375%3A0xfeeea4b8a06862ee!2s3890%20Satellite%20Blvd%2C%20Duluth%2C%20GA%2030096%2C%20USA!5e0!3m2!1sen!2sin!4v1675448058740!5m2!1sen!2sin",
	},
	{
		address: "3230 Caliber St, Suwanee, GA 30024",
		icon: jb,
		brand: "jb",
		brandFullName: "Jay Bhavani",
		storeId: "N.A",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.4372328143922!2d-84.16634794872814!3d34.083937923410446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5996aef5f2a57%3A0x88f86c3eda0d6edf!2s3230%20Caliber%20St%2C%20Suwanee%2C%20GA%2030024%2C%20USA!5e0!3m2!1sen!2sin!4v1675448053838!5m2!1sen!2sin",
	},
	{
		address: "2883 N Decatur Rd A, Decatur, GA 30033",
		icon: jb,
		brand: "jb",
		brandFullName: "Jay Bhavani",
		storeId: "N.A",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.816270271219!2d-84.2763426487329!3d33.791242438790675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5077c1688c437%3A0x804627000d944c79!2s2883%20N%20Decatur%20Rd%20A%2C%20Decatur%2C%20GA%2030033%2C%20USA!5e0!3m2!1sen!2sin!4v1675448143716!5m2!1sen!2sin",
	},
	{
		address: "3890 Satellite blvd Duluth Ga 30096",
		icon: jb,
		brand: "jb",
		brandFullName: "Jay Bhavani",
		storeId: "N.A",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.537489292029!2d-84.14467704873032!3d33.95302203030372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a309f4355375%3A0xfeeea4b8a06862ee!2s3890%20Satellite%20Blvd%2C%20Duluth%2C%20GA%2030096%2C%20USA!5e0!3m2!1sen!2sin!4v1675448058740!5m2!1sen!2sin",
	},
	{
		address: "70 Hwy 138 SE, Stockbridge, GA 30281",
		icon: ck,
		brand: "ck",
		brandFullName: "Checkers",
		storeId: "3082",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.978002751184!2d-84.2631398487368!3d33.55394785117579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f4561841a5341d%3A0x62f1dfdff9553a56!2s70%20Hwy%20138%20SE%2C%20Stockbridge%2C%20GA%2030281%2C%20USA!5e0!3m2!1sen!2sin!4v1675448159468!5m2!1sen!2sin",
	},
	{
		address: "1685 Church St 2nd floor, #209, Decatur, GA 30033",
		icon: zt,
		brand: "zt",
		brandFullName: "Zest LED services",
		storeId: "N.A",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.6006189699356!2d-84.28384684873281!3d33.796810238499226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50777eb3ae70b%3A0x746f8daf6b2c4ae5!2s1685%20Church%20St%202nd%20209%2C%20Decatur%2C%20GA%2030033%2C%20USA!5e0!3m2!1sen!2sin!4v1675448175076!5m2!1sen!2sin",
	},
	{
		address: "1685 Church St 2nd floor, #209, Decatur, GA 30033",
		icon: zt,
		brand: "zt",
		brandFullName: "Zest Handy services",
		storeId: "N.A",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.6006189699356!2d-84.28384684873281!3d33.796810238499226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50777eb3ae70b%3A0x746f8daf6b2c4ae5!2s1685%20Church%20St%202nd%20209%2C%20Decatur%2C%20GA%2030033%2C%20USA!5e0!3m2!1sen!2sin!4v1675448175076!5m2!1sen!2sin",
	},
	{
		address: "1685 Church St 2nd floor, #209, Decatur, GA 30033",
		icon: zt,
		brand: "zt",
		brandFullName: "Zest world travels",
		storeId: "N.A",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.6006189699356!2d-84.28384684873281!3d33.796810238499226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50777eb3ae70b%3A0x746f8daf6b2c4ae5!2s1685%20Church%20St%202nd%20209%2C%20Decatur%2C%20GA%2030033%2C%20USA!5e0!3m2!1sen!2sin!4v1675448175076!5m2!1sen!2sin",
	},
	{
		address: "1942 Atkinson Rd NW Ste 900, Lawrenceville, GA 30043",
		icon: br,
		brand: "br",
		brandFullName: "Baskin Robbins",
		storeId: "351323",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.5806553015514!2d-84.08221084872991!3d33.97761632901053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5bd180035943f%3A0xa79ee59c686ce964!2s1942%20Atkinson%20Rd%20NW%20%23900%2C%20Lawrenceville%2C%20GA%2030043%2C%20USA!5e0!3m2!1sen!2sin!4v1675447587685!5m2!1sen!2sin",
	},
	{
		address: "4092 Lawrenceville Hwy, Tucker, GA 30084",
		icon: br,
		brand: "br",
		brandFullName: "Baskin Robbins",
		storeId: "352613",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.5062094144537!2d-84.21733384873197!3d33.850842835667954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a63ad3b42807%3A0x94d0117c5889e27c!2s4092%20Lawrenceville%20Hwy%2C%20Tucker%2C%20GA%2030084%2C%20USA!5e0!3m2!1sen!2sin!4v1675447682837!5m2!1sen!2sin",
	},
	{
		address: "5870 Cumming Hwy NE, Sugar Hill, GA 30518",
		icon: br,
		brand: "br",
		brandFullName: "Baskin Robbins",
		storeId: "354030",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.136263145165!2d-84.0788475487276!3d34.11726112165208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f590c13beed375%3A0xbacf2ff504f20df6!2s5870%20Cumming%20Hwy%20NE%2C%20Buford%2C%20GA%2030518%2C%20USA!5e0!3m2!1sen!2sin!4v1675447688977!5m2!1sen!2sin",
	},
	{
		address: "1800 Eatonton Rd, Madison, GA 30650",
		icon: br,
		brand: "br",
		brandFullName: "Baskin Robbins",
		storeId: "357150",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.6245882917424!2d-83.4800309487366!3d33.563128950698086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f686ec2b769ced%3A0x8414be454e9072c5!2s1800%20Eatonton%20Rd%2C%20Madison%2C%20GA%2030650%2C%20USA!5e0!3m2!1sen!2sin!4v1675447744389!5m2!1sen!2sin",
	},
	{
		address: "1270 Spring St NW, Atlanta, GA 30309",
		icon: br,
		brand: "br",
		brandFullName: "Baskin Robbins",
		storeId: "358682",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.8787427995067!2d-84.39211844873297!3d33.789629338875166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045a3417c7a1%3A0x27c8d16a32f8e250!2s1270%20Spring%20St%20NW%2C%20Atlanta%2C%20GA%2030309%2C%20USA!5e0!3m2!1sen!2sin!4v1675447736373!5m2!1sen!2sin",
	},
	{
		address: "881 Peachtree St NE, Atlanta, GA 30309",
		icon: br,
		brand: "br",
		brandFullName: "Baskin Robbins",
		storeId: "359606",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.3016402900657!2d-84.38625494873315!3d33.778707939446676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5046844588ac9%3A0xab168a41e1ce6fc7!2s881%20Peachtree%20St%20NE%2C%20Atlanta%2C%20GA%2030309%2C%20USA!5e0!3m2!1sen!2sin!4v1675447763142!5m2!1sen!2sin",
	},
	{
		address: "2550 Lawrenceville Hwy, Decatur, GA 30033",
		icon: br,
		brand: "br",
		brandFullName: "Baskin Robbins",
		storeId: "359791",
		iframelink:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.6541223717063!2d-84.26674744873243!3d33.821237837219684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a818cba5230f%3A0xf61feefe9456051a!2s2550%20Lawrenceville%20Hwy%2C%20Decatur%2C%20GA%2030033%2C%20USA!5e0!3m2!1sen!2sin!4v1675447786869!5m2!1sen!2sin",
	},
];
