import React from "react";
import "./AboutUs.css";
import CeoImg from "../../Assets/AboutUs/ceo.png";
import CtoImg from "../../Assets/AboutUs/cto.png";
import OurTeamImg from "../../Assets/AboutUs/team.png";

const AboutUs = () => {
	return (
		<div className='aboutus-container main'>
			<div className='hero'>
				<h2>Who We Are</h2>
				<p>
					{`The most important thing for us  is to give you the \n comfortable dining experience`}
				</p>
			</div>
			<div className='business-info'>
				<div className='info-text'>
					<h2>Our Story</h2>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
						obcaecati eius quasi, provident quas exercitationem eaque eveniet
					</p>
				</div>
				<div className='img-div info-img'></div>
				<div class='info-graphic-tomato'></div>
			</div>

			<div className='ceo-cto'>
				<div className='ceo-content'>
					<div>
						<h2>CEO</h2>
						<h5>Mark Hughes</h5>
						<img className='img-div' src={CeoImg} />
					</div>
					<p>
						{`Lorem ipsum dolor sit amet, consectetur\n adipiscing elit. Et in sed in pellentesque ornare \nnunc nisl. Augue habitant accumsan, ut\n parturient orci ac etiam congue mi. `}
					</p>
				</div>
				<div className='ceo-content'>
					<p className='text-start'>
						{`Lorem ipsum dolor sit amet, consectetur \n adipiscing elit. Et in sed in pellentesque ornare \n nunc nisl. `}
					</p>
					<div>
						<h2>CTO</h2>
						<h5>Timber Lake</h5>
						<img className='img-div' src={CtoImg} />
					</div>
				</div>
			</div>

			<div className='our-team'>
				<h2>Our Team</h2>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem
					id penatibus imperdiet. Turpis egestas ultricies purus auctor
					tincidunt lacus nunc.{" "}
				</p>
				<div className='team-content'>
					{[1, 2, 3, 4].map(() => (
						<div className='team-details'>
							<img src={OurTeamImg}></img>
							<p className='team-name'>
								Tim Cook, <span>Consultant</span>
							</p>
							<p className='team-desc'>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus
								lorem id penatibus imperdiet. Turpis egestas ultricies purus
								auctor tincidunt lacus nunc.{" "}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
