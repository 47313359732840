import React, { useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Contact = () => {
	const MySwal = withReactContent(Swal);
	const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm("123", "123", form.current, "123").then(
			(result) => {
				if (result.status === 200) {
					MySwal.fire(
						"Message Sent!",
						"You message has been sent Sucessfully!",
						"success"
					);
					form.current.reset();
				}
			},
			(error) => {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong! Sorry for the inconvenience",
					footer: '<p>Consider sending email to <a href="mailto: "> </a></p>',
				});
			}
		);
	};

	return (
		<div className='container main'>
			<div className='hero'>
				<h2>Get in Touch</h2>
				<p>The freshest ingredients for you every day</p>
			</div>
			<div className='info-content'>
				<div className='contact-graphics'></div>
				<div className='contact-image'></div>
				<div className='info'>
					<p>
						We can be contacted via email <span>info@foodzero.com</span> or
						telephone on <span> +86 852 346 000</span>
					</p>
				</div>
			</div>
			<div className='contact-us'>
				<div className='mint'></div>
				<h1>Get In Touch</h1>
				<form
					action=''
					className='contact-form'
					id='contact-form'
					onSubmit={sendEmail}
					ref={form}
				>
					<div className='contact-name'>
						<input type='text' placeholder='First Name' name='firstName' />
						<input type='text' placeholder='Last Name' name='lastName' />
					</div>
					<input type='email' placeholder='Email' name='email' />
					<input type='number' name='phone' placeholder='Phone' />
					<textarea
						name='message'
						placeholder='Message'
						cols='30'
						rows='10'
					></textarea>
					<button type='submit' id='submit' value='Submit'>
						Submit
					</button>
				</form>
			</div>
		</div>
	);
};

export default Contact;
